@import '../../styles/component.scss';

.moduleContainerWrap {
  composes: section container from global;

  @include media-breakpoint-down(sm) {
    width: calc(100vw - 4rem);
  }
}
.horozontalLine{
 border-top: 2px solid rgba(0, 0, 0, 0.1)
}
.headertext .summarytext{
  font-family: "Atlas Grotesk";
  font-size: 16px !important;
  font-weight: 500;
  line-height: 24px;
}
.headertext{
  margin: 8px 0px;
}
.summarytext{
 font-weight: 400;
}
.arrowbutton{
  font-size: 20px;
  border: none;
  background:white;
}
.newarrowbutton{
  font-size: 20px;
  border: none;
  background:#faf9f7
}
.wrapcontainer{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
  // padding:32px 0px 32px 12px
}
.slideSummaryText{
  display: none;
}
.slideSummaryText.visible{
  display: block;
}
.slideSummaryText.hidden{
  display: none;
}
@import '../../styles/component.scss';

.moduleContainerWrap {
  composes: section container from global;

  @include media-breakpoint-down(sm) {
    width: calc(100vw - 4rem);
  }
}

.horozontalLine {
  border-top: 2px solid rgba(0, 0, 0, 0.1)
}

.parentContainer {
  .headerText {
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1.375rem;
    align-content: center;
    margin-left: 0.499rem;
    font-family: "AtlasGrotesk", "Open Sans", sans-serif !important;
    margin-bottom: 0px;

  }

}
.parentContainer{
.summaryText {
  font-weight: 400;
  margin-left: 2rem;
  margin-bottom: 2.125rem;
  font-size: 0.875rem;
  line-height: 1.375rem;
  font-family: "AtlasGrotesk", "Open Sans", sans-serif !important;
}}

.arrowbutton {
  font-size: 20px;
  border: none;
  background: white;
}

.newarrowbutton {
  font-size: 20px;
  border: none;
  background: #faf9f7
}

.checklistWrapper {
  .internalTitle {
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.5rem;
    font-family: "AtlasGrotesk", "Open Sans", sans-serif !important;
    margin-bottom: 1.125rem;
  }
}

.checklistContainer {
  display: flex;
  margin-bottom: 0.75rem;
}